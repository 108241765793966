#header {

	.header-top {
		position: relative;
		color: #ffffff;
		padding: 9px 0;
		background-color: #000000;

		a {
			color: #ffffff;
		}

		.txt {
			position: relative;
			display: inline-block;
			padding: 4px 0;
			font-size: 0.95rem;

			.ic {
				position: relative;
				top: 2px;
				margin-right: 10px;
				font-size: 125%;
			}
		}

		@media screen and (max-width: 991px) {
			padding: 5px 0;
			text-align: center;

			.txt {
				font-size: 0.85rem;
			}
		}
		@media screen and (max-width: 575px) {
			padding: 4px 0 8px 0;
			line-height: 1.2;

			.txt {
				padding: 1px 0;
				font-size: 0.75rem;

				.ic {
					margin-right: 4px;
				}
			}
		}
	}

	.header-menu {
		.navbar {
			padding-top: 0.7rem;
			padding-bottom: 0.7rem;
			background-color: #ffffff !important;

			.navbar-brand {
				img {
					max-height: 44px;
				}
			}

			.navbar-toggler {
				padding: 0.45rem 0.95rem;
				font-size: 1.40rem;
				color: #ffffff;
				border: 0;
				border-radius: 2px;
				background-color: $cprimary;
			}

			#nav-collapse {
				.navbar-nav {
					.nav-item  {
						.nav-link {
							cursor: pointer;
						}
					}

					.simple-item {
						.nav-link {
							color: #151515;
							font-size: 1.05rem;
							font-weight: 600;
							letter-spacing: 2px;
							text-transform: uppercase;
						}
					}

					.button-item {
						.nav-link {
							padding: 0.54rem 1.2rem 0.53rem 1.2rem;
							color: #ffffff;
						  font-size: 0.95rem;
							font-weight: 600;
						  letter-spacing: 3px;
							text-transform: uppercase;
							background-color: $cprimary;
						}
					}

					.form-search {
						@include placeholder(#b5b4b4, 400, normal, 0.99rem);

						.form-inline {
							border: 1px solid #cecece;
							border-radius: 4px;
							overflow: hidden;

							.btn {
								padding: 0.25rem 1.1rem;
								height: 40px;
								color: #2b2b2b;
								font-size: 1.25rem;
								border: 0;
								border-radius: 0;
								background-color: transparent !important;

								&:hover {
									color: #ffffff;
									background-color: $cprimary !important;
								}
							}

							.form-control {
								padding-left: 0.50rem;
								border: 0;
								height: 40px;
								font-size: 0.99rem;
							}
						}
					}

					.item-w-icon {
						position: relative;

						.nav-link {
							padding-top: 0.6rem;

							&:after {
								color: #151515;
							}

							.txt-ic {
								color: #151515;

								.ic {
									font-size: 1.4rem;
								}

								.txt {
									padding-left: 3px;
									font-size: 1.05rem;
									font-weight: 500;
								}
							}
						}
					}

					.dropdown {
						.dropdown-menu {
							border-radius: 2px;
							border: 0;
							background-color: $cprimary;

							li {
								.dropdown-item {
									padding: 0.20rem 1.5rem;
									color: #ffffff;
									font-size: 0.90rem;

									&:hover {
										background-color: $cprimary2;
									}
								}
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px) {
			.navbar {
				#nav-collapse {
					.navbar-nav {
						.nav-item {
							margin-left: 0.65rem;
							margin-right: 0.65rem;
						}

						.button-item {
							margin-left: 0.8rem;
						}

						.form-search {
							margin-right: 1.1rem;
						}

						.item-w-icon {
							margin-left: 0.75rem;
  						margin-right: 0.75rem;

							.nav-link {
								.txt-ic {
									.txt {
										display: none;
									}
								}
							}

							&.b-right {
								&:after {
									content: " ";
									position: absolute;
									top: 5px;
									right: -0.75rem;
									height: calc(100% - 10px);
									border-left: 1px solid #606060;
								}
							}
						}

						.dropdown {
							.dropdown-menu {
								border-radius: 0 0 2px 2px;
								margin-top: 16px;
							}
						}
					}
				}
			}

			@media screen and (max-width: 1399px) {
				.navbar {
					#nav-collapse {
						.navbar-nav {
							.nav-item {
								margin-left: 0.25rem;
								margin-right: 0.25rem;
							}

							.form-search {
								margin-right: 0.5rem;
							}

							.item-w-icon {
								&.b-right {
									&:after {
										right: -0.25rem;
									}
								}
							}
						}
					}
				}
			}
			@media screen and (max-width: 1199px) {
				.navbar {
					.navbar-brand {
						img {
							max-height: 35px;
						}
					}

					#nav-collapse {
						.navbar-nav {
							.nav-item {
								margin-left: 0;
								margin-right: 0;

								.nav-link {
									font-size: 0.90rem;
								}
							}

							.button-item {
								.nav-link {
									font-size: 0.85rem;
									padding: 0.50rem 1.0rem 0.50rem 1.0rem;
								}
							}

							.item-w-icon {
								margin-left: 0.25rem;
								margin-right: 0.25rem;

								&.b-right {
									&:after {
										right: -0.25rem;
									}
								}
							}
						}
					}
				}
			}
			@media screen and (max-width: 1099px) {
				.navbar {
					#nav-collapse {
						.form-search {
							display: none !important;
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			.navbar {
				padding-top: 0.4rem;
				padding-bottom: 0.4rem;

				.navbar-brand {
					img {
						height: 31px;
					}
				}

				#nav-collapse {
					.navbar-nav {
						.nav-item {
							.nav-link {
								padding-top: 0.2rem;
								padding-bottom: 0.2rem;
							}
						}

						.form-search {
							margin-bottom: 8px;
							width: 100%;

							.form-inline {
								width: 100%;
								border-radius: 0;

								.btn {
									width: 56px;
								}

								.form-control {
									width: calc(100% - 56px);
								}
							}
						}
					}
				}
			}
		}
	}

	// == Products menu ==
	.products-menu{
		position: fixed;
		padding-top: 15px;
		padding-bottom: 15px;
		top: 0;
		left: 0;
		width: 335px;
		height: 100%;
		color: #fff;
		font-size: 15px;
		box-shadow: 1px 0px 2px rgba(0,0,0,0.2);
		background: $cprimary;
		opacity: 0;
		visibility: hidden;
		z-index: 480;
		@include cp-property(transform, translateX(-380px) !important);

		a{
			color: #fff;
		}

		.menu-bg{
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100%;
			background-color: transparent;
			z-index: 1;
		}

		.tablist{
			position: relative;
			width: 100%;
			height: 100%;
			overflow: auto;
			overflow-x: hidden;
			z-index: 2;

			.box-title{
				padding: 8px 0px;
				margin-bottom: 6px;
				font-weight: 400;
				text-align: center;
				cursor: pointer;

				&:hover{
					opacity: 0.8;
				}

				h4{
					font-size: 1.3rem;
				}

				.icon{
					position: absolute;
					top: 13px;
					right: 24px;
					font-size: 1.2rem;
				}
			}
		}

		.card{
			border: 0;
			border-radius: 0;
			background-color: transparent;

			.card-header{
				background-color: transparent;
				border: 0;
				border-radius: 0;
				padding: 0.2rem;

				.btn-toggle{
					position: relative;
					display: block;
					padding: 0px 45px 0px 10px;
					text-align: right;
					color: #fff;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 700;
					cursor: pointer;
					@extend .t-250;

					.fake-toggle{
						position: absolute;
						right: 0;
						top: 0;
						width: 43px;
						height: 100%;
						z-index: 10;
					}

					&:after{
						content: " ";
						position: absolute;
						top: 7px;
						right: 18px;
						width: 16px;
						height: 8px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						background-image: url('../images/shared/chevron-down.svg');
						z-index: 1;
					}

					&:hover{
						opacity: 0.8;
					}
				}
				.btn-toggle-fake{
					position: relative;
					display: block;
					padding: 0px 45px 0px 10px;
					text-align: right;
					color: #fff;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 700;
					cursor: pointer;
					@extend .t-250;

					.fake-toggle{
						position: absolute;
						right: 0;
						top: 0;
						width: 43px;
						height: 100%;
						z-index: 10;
					}

					&:hover{
						opacity: 0.8;
					}
				}

				.btn-direct-link{
					position: relative;
					right: -43px;
					padding-right: 43px;
				}
			}

			.card-body{
				padding: 0.4rem 0;
				line-height: 1.3;
				background-color: $cprimary2;

				.ul-sub-options{
					padding-left: 0;
					margin-bottom: 0;
					list-style-type: none;

					li{
						margin-top: 5px;
						margin-bottom: 5px;
						display: inline-block;
						width: 100%;
						font-size: 14px;
						text-align: right;

						a{
							display: block;
							padding: 0px 1.45rem;

							&:hover{
								text-decoration: none;
								opacity: 0.8;
							}
						}
					}
				}
			}
		}

		&.active{
			opacity: 1;
			visibility: visible;
			@include cp-property(transform, translateX(0px) !important);
		}
	}
	// == ==

}
