.box-product-s1 {
  .box-image {
    position: relative;
    background-color: $soft-gray;

    img {
      width: 100%;
    }
  }

  .box-info {
    padding: 12px 0;
    color: #272727;

    .name {
      margin-bottom: 2px;
      min-height: 38px;
      font-size: 1.10rem;
      font-weight: 700;
      line-height: 1.10;
    }

    .category {
      margin-bottom: 6px;
      color: #464646;
      font-size: 0.85rem;
    }

    .price {
      color: #464646;
      font-size: 1.15rem;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 1399px) {
    .box-info {
      .name {
        font-size: 1.05rem;
      }
    }
  }
  @media screen and (max-width: 575px) {
    &.px-mob {
      .box-info {
        padding: 14px 15px;

        .name {
          font-size: 1.1rem;
        }
      }
    }
  }
}

a.box-product-s1 {
  text-decoration: none;
  @include transition(250ms);

  &:hover {
    opacity: 0.85;
  }
}
