#thank-you-page {

  .main-section {
    padding-top: 40px;
    padding-bottom: 40px;

    >.row {
      min-height: 55vh;
      align-items: center;
    }

    .col-info {
      text-align: center;

      .logo {
        width: 310px;
        max-width: 97%;
      }

      .title {
        font-size: 1.85rem;
        font-weight: 700;
      }

      p {
        font-size: 1.05rem;
        font-weight: 500;
      }

      .btn-network {
        display: inline-block;
        margin: 0 14px;
        width: 44px;
        height: 44px;
        color: #ffffff;
        font-size: 1.4rem;
        text-align: center;
        background-color: $cprimary;
        line-height: 2.7rem;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background-color: $cprimary2;
        }
      }

      .btn-s1 {
        min-width: 195px;
      }
    }
  }

}
