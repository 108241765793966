#footer {
  position: relative;
  padding: 30px 0 16px 0;
  background-color: #f3f3f3;

  .col-footer {
    padding-top: 10px;
    padding-bottom: 10px;

    .link-s1 {
      color: #272727;
      font-size: 1.05rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    .txt-w-icon {
      display: inline-block;
      color: #272727;
      font-size: 1.15rem;
      font-weight: 400;

      .ic {
        display: inline-block;
        margin-right: 5px;
        width: 21px;
        font-size: 1.05rem;
      }
    }
    a.txt-w-icon {
      text-decoration: none !important;

      &:hover {
        opacity: 0.85;
      }
    }

    .btn-network {
      display: inline-block;
      margin: 0 6px;
      width: 38px;
      height: 38px;
      color: #ffffff;
      font-size: 1.2rem;
      text-align: center;
      background-color: $cprimary;
      line-height: 2.3rem;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background-color: $cprimary2;
      }
    }
  }

  .col-logo {
    a {
      display: inline-block;
      margin-left: 10%;

      img {
        max-height: 164px;
      }
    }
  }

  .col-copy {
    margin-top: 22px;
    text-align: center;

    h6 {
      color: #4f4f4f;
      font-size: 0.95rem;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 20px 0 16px 0;

    .col-footer {
      text-align: center;
    }

    .col-logo {
      a {
        margin-left: 0;
      }
    }

    .col-copy {
      margin-top: 6px;
    }
  }
  @media screen and (max-width: 575px) {
    padding: 14px 0 6px 0;

    .col-copy {
      margin-top: 0;
      
      h6 {
        font-size: 0.80rem;
      }
    }
  }
}
