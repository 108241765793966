.extra-info-component {
  position: relative;
  padding-top: 36px;
  padding-bottom: 36px;
  color: #ffffff;
  background-color: $soft-gray;
  background-image: url('../images/pages/home/bg-info.jpg');

  .col-info {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;

    &:after {
      content: " ";
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -42px;
      height: 84px;
      border-right: 1px solid #ffffff;
    }

    .txt-icon {
      margin-bottom: 16px;

      i {
        font-size: 2.5rem;
      }

    }

    .title {
      font-weight: 400;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .col-info {
      .txt-icon {
        margin-bottom: 12px;

        i {
          font-size: 2.3rem;
        }
      }

      .title {
        font-size: 1.35rem;
      }
    }
  }
  @media screen and (max-width: 767px) {
    background-image: url('../images/pages/home/bg-info-m.jpg');
    padding-top: 22px;
    padding-bottom: 22px;

    .col-info {
      padding-top: 25px;
      padding-bottom: 25px;

      &:after {
        top: inherit;
        bottom: 0;
        right: 50%;
        margin-right: -60px;
        width: 120px;
        border-right: 0;
        border-bottom: 1px solid #ffffff;
      }

      .txt-icon {
        margin-bottom: 8px;
      }
    }
  }
}
