#home-page {

	.banner-section {
		position: relative;
		width: 100%;

		.swiper {
			.swiper-slide {
				.box {
					display: block;

					img {
						width: 100%;
					}
				}
			}

			.swiper-button-prev, .swiper-button-next {
				width: 60px;
				height: 60px;
				color: #ffffff;
				border: 2px solid #ffffff;
				border-radius: 50%;
				background-color: transparent;
				@include transition(150ms);
				opacity: 1;

				&:after {
					font-family: "Font Awesome 5 Pro";
					font-weight: 500;
					font-size: 1.9rem;
				}

				&:hover {
					background-color: rgba(255,255,255,0.10);
				}
			}

			.swiper-button-prev {
				left: 20px;
				padding-right: 2px;

				&:after {
					content: "\f053";
				}
			}

			.swiper-button-next {
				right: 20px;
				padding-left: 3px;

				&:after {
					content: "\f054";
				}
			}
		}

		.desktop {}
		.mobile {
			display: none;
		}

		@media screen and (max-width: 767px) {
			.desktop {
				display: none;
			}
			.mobile {
				display: block;
			}

			.swiper {
				.swiper-button-prev, .swiper-button-next {
					width: 52px;
					height: 52px;

					&:after {
						font-size: 1.55rem;
					}
				}

				.swiper-button-prev {
					left: 14px;
				}
				.swiper-button-next {
					right: 14px;
				}
			}
		}
	}

	.products-section {
		padding: 40px 0;

		.box-title {
			display: block;
			padding: 0 15px;
			margin-bottom: 28px;
			text-align: center;
		}

		.box-products {
			position: relative;
			display: inline-block;
			width: 100%;

			.swiper {
				.swiper-button-prev {
					left: 0;
				}
				.swiper-button-next {
					right: 0;
				}

				.swiper-button-prev, .swiper-button-next {
					margin-top: -25px;
					width: 34px;
					height: 50px;
					background-color: $cprimary;

					&:after {
						color: #ffffff;
						font-size: 1.5rem;
						font-weight: 700;
					}
				}

				&.s2 {
					.swiper-button-prev, .swiper-button-next {
						margin-top: -75px;
					}
				}
			}
		}

		@media screen and (max-width: 1399px) {
			padding: 30px 0;
		}
		@media screen and (max-width: 991px) {
			.box-title {
				margin-bottom: 20px;
			}
		}
		@media screen and (max-width: 575px) {
			.box-title {
				margin-bottom: 18px;
			}
		}
	}

}
