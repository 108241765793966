.btn-s1 {
  padding: 0.64rem 1.6rem 0.63rem 1.6rem;
  color: #ffffff !important;
  font-size: 0.95rem;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  border-radius: 0;
  border-color: $cprimary;
  background-color: $cprimary;

  &:hover {
    border-color: $cprimary2;
    background-color: $cprimary2;
  }
}
