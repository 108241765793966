.title-s1 {
  font-size: 2.3rem;
  font-weight: 500;
  line-height: 1.05;

  @media screen and (max-width: 1199px) {
    font-size: 2.1rem;
  }
  @media screen and (max-width: 575px) {
    font-size: 1.80rem;
  }
}
