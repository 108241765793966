#company-page {

  .basic-info-section {
    position: relative;
    border-bottom: 8px solid #272727;
    background-color: #cad1d0;

    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background-position: right center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('../images/pages/company/img-1.jpg');
    }

    .container {
      >.row {
        min-height: 77vh;
        align-items: center;
      }
    }

    .col-info {
      line-height: 1.3;
      text-align: justify;

      .title {
        font-size: 2.4rem;
        font-weight: 700;
      }

      .p-lg {
        line-height: 1.4;
        font-size: 1.35rem;
      }
    }

    @media screen and (max-width: 767px) {
      padding-top: 110px;
      padding-bottom: 50px;

      .bg-image {
        top: 0;
        width: 100%;
        height: 100px;
        border-bottom: 2px solid #7b7777;
        background-position: center top;
      }

      .container {
        >.row {
          min-height: inherit;
        }
      }

      .col-info {
        padding-top: 20px;
        padding-bottom: 5px;

        .title {
          text-align: center;
        }
      }
    }
    @media screen and (max-width: 575px) {
      .bg-image {
        background-position: right 43% top;
        background-size: 240%;
      }
    }
  }

  .extra-info-section {
    padding-top: 70px;
    padding-bottom: 70px;

    .row-info {
      align-items: center;
      justify-content: center;

      .col-image {
        .circle {
          position: relative;
          display: inline-block;
          border: 6px solid #dadcdf;
          border-radius: 50%;

          img {
            width: 100%;
          }
        }
      }

      .col-text {
        .title {
          color: #4d4f51;
          font-size: 2.8rem;
          font-weight: 700;
          letter-spacing: 3px;
        }

        p {
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.4;
          letter-spacing: 2px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .row-info {
        .col-text {
          margin-top: 10px;
          text-align: center !important;
        }
      }
    }
    @media screen and (max-width: 575px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .benefits-section {
    position: relative;
    padding-top: 48px;
    padding-bottom: 52px;
    text-align: center;
    background: #f2f5f7;

    .title {
      margin-bottom: 15px;
      font-size: 2.0rem;
      font-weight: 700;
    }

    .list-b {
      font-size: 1.1rem;
      font-weight: 500;

      .ic {
        position: relative;
        margin-right: 2px;
        top: -2px;
        font-size: 74%;
      }
    }

    .btn-contact {
      display: inline-block;
      padding: 10px 22px 11px 22px;
      color: #50555e;
      font-size: 1.20rem;
      font-weight: 700;
      letter-spacing: 2px;
      text-decoration: none !important;
      border: 3px solid #50555e;
      border-radius: 8px;

      &:hover {
        color: #ffffff;
        background-color: #50555e;
      }
    }

    h5 {
      font-size: 1.15rem;
      font-weight: 600;
    }
  }

  .trainings-section {
    position: relative;
    background-color: #edd283;

    .bg-image {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background-position: left center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('../images/pages/company/img-4.jpg');
    }

    .container {
      >.row {
        min-height: 77vh;
        align-items: center;
      }
    }

    .col-info {
      line-height: 1.4;
      font-size: 1.10rem;
      text-align: justify;

      .title {
        font-size: 2.2rem;
        font-weight: 700;
      }

      ol, ul {
        padding-left: 15px;
      }

      ul {
        list-style-type: circle;
      }

      h5 {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }

    @media screen and (max-width: 767px) {
      padding-top: 110px;
      padding-bottom: 50px;

      .bg-image {
        top: 0;
        width: 100%;
        height: 100px;
        border-bottom: 2px solid #7b7777;
        background-position: center top;
      }

      .container {
        >.row {
          min-height: inherit;
        }
      }

      .col-info {
        padding-top: 20px;
        padding-bottom: 5px;

        .title {
          text-align: center;
        }
      }
    }
    @media screen and (max-width: 575px) {
      .bg-image {
        background-position: right 43% top;
        background-size: 240%;
      }
    }
  }

  .testimonials-section {
    padding-top: 50px;
    padding-bottom: 50px;

    .col-title {
      text-align: center;

      .title {
        margin-bottom: 5px;
        font-size: 2.2rem;
        font-weight: 700;
      }

      .subtitle {
        font-size: 1.2rem;
      }
    }

    .col-carousel {
      margin-top: 25px;

      .swiper {
        .swiper-button-prev, .swiper-button-next {
          &:after {
            color: $cprimary;
            font-size: 1.9rem;
            font-weight: 700;
          }
        }

        .swiper-button-prev {
          left: 0;
        }
        .swiper-button-next {
          right: 0;
        }
      }

      .box-testimonial {
        position: relative;
        display: inline-block;
        width: 100%;
        padding-left: 45px;
        padding-right: 45px;
        text-align: center;

        .box-photo {
          display: block;

          .image {
            display: inline-block;
            width: 190px;
            height: 190px;
            border: 3px solid #dadcdf;
            border-radius: 50%;
            background-color: #ffffff;
          }
        }

        .box-info {
          margin-top: 6px;

          .name {
            font-size: 1.4rem;
            font-weight: 600;
          }

          .descr {
            margin-top: 6px;
            font-size: 0.95rem;
            line-height: 1.4;
          }
        }
      }
    }
  }

  .thanks-section {
    position: relative;
    padding-top: 38px;
    padding-bottom: 38px;
    color: #ffffff;
    text-align: center;
    background-color: $cprimary;

    .title {
      margin-bottom: 12px;
      font-size: 2.0rem;
      font-weight: 700;
    }

    .subtitle {
      font-size: 1.20rem;
    }

    @media screen and (max-width: 575px) {
      .title {
        font-size: 1.7rem;
      }

      .subtitle {
        font-size: 1.1rem;
      }
    }
  }

}
