#contact-page {

  .main-section {
    position: relative;
    width: 100%;
    overflow: hidden;

    .container {
      >.row {
        margin-top: -82px;
        align-items: center;
      }
    }

    .box-title {
      position: relative;
      display: inline-block;
      padding-top: 25px;
      padding-bottom: 25px;
      height: 82px;
      width: 100%;
      background-color: #efefef;

      &:before {
        content: " ";
        position: absolute;
        bottom: 0;
        left: -30vw;
        width: 30vw;
        height: 82px;
        background-color: #efefef;
      }

      &:after {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60vw;
        height: 82px;
        background-color: #efefef;
      }

      h1 {
        position: relative;
        font-size: 1.65rem;
        font-weight: 600;
        z-index: 20;
      }
    }

    .col-form {
      padding-top: 90px;
    }

    .col-image {
      .img {
        position: relative;
        width: 43vw;
        height: 80vh;
        background-color: $soft-gray;
      }
    }

    @media screen and (max-width: 991px) {
      .col-form {
        padding-bottom: 30px;
      }

      .col-image {
        display: none;
      }
    }
  }

}
