.box-catalog-s1 {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: $soft-gray;

  &:before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.15);
  }

  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.6) 10%, rgba(0,0,0,0) 100%);
  }

  .empty {
    display: block;
    width: 100%;
  }

  .box-name {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    z-index: 30;

    .name {
      color: #ffffff;
      text-align: center;
      font-size: 1.9rem;
    }
  }

  @media screen and (max-width: 767px) {
    .box-name {
      .name {
        font-size: 1.70rem;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .box-name {
      .name {
        font-size: 2.0rem;
      }
    }
  }
}
a.box-catalog-s1 {
  cursor: pointer;
  @include transition(250ms);

  &:hover {
    opacity: 0.90;
  }
}
