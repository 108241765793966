#info-page-s1{

	.banner-section{
		position: relative;
		width: 100%;
		height: 180px;
		background-color: $soft-gray;
		box-shadow: 0 1px 3px rgba(0,0,0,0.4);
	}

	.main-section{
		padding-top: 35px;
		padding-bottom: 35px;
		min-height: 68vh;
		font-size: 0.95rem;
		line-height: 1.25;

		&.m-h-inherit{
			min-height: inherit;
		}

		h2,h3,h4,h5,h6{
			font-weight: 600;
		}

		h5{
			font-size: 1.10rem;
		}

    ul, ol {
      padding-left: 15px;
    }

		.title-s-1{
			text-align: center;
		}
	}

}
